import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useForm, ValidationError } from '@formspree/react';
import MyButton from '../tools/MyButton';
import MyInput from '../tools/MyInput';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
}));


const ContactsPage = () => {
  const classes = useStyles();
  const [state, handleSubmit] = useForm("xvodbdeb");
  if (state.succeeded) {
      return <p>Sugestão enviada com sucesso!</p>;
  }

  return (
    <div >
      <form onSubmit={handleSubmit}>
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} >
                <label htmlFor="name">Nome</label><br/>
                <MyInput id="nome" type="text" name="nome" style={{width: '100%'}}/>
                <ValidationError prefix="Nome" field="nome" errors={state.errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="email">E-mail</label><br/>
              <MyInput id="email" type="email" name="email" style={{width: '100%'}} />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="phone">Telemóvel</label><br/>
              <MyInput id="phone" type="text" name="phone" style={{width: '100%'}} />
              <ValidationError prefix="Telemóvel" field="phone" errors={state.errors} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <label htmlFor="freguesia">Freguesia</label><br/>
              <MyInput id="freguesia" type="text" name="freguesia" style={{width: '100%'}} />
              <ValidationError prefix="Freguesia" field="freguesia" errors={state.errors} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <label htmlFor="freguesia">Mensagem</label><br/>
              <textarea style={{width: '100%'}} id="message" name="message" /><br/>
              <ValidationError prefix="Message" field="message" errors={state.errors} />
              <MyButton type="submit" style={{minWidth: 100, marginTop: 27}}>Enviar</MyButton>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  )
}

export default ContactsPage

