import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-vetespinho-com/src/components/layout.js";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import ContactsPage from "../components/contacts.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Contactos" description="Clínica Veterinária - Do cão ao gato passando pela tartaruga, o periquito e o coelho, o seu animal de estimação merece os nossos cuidados.
" image={'https://www.vetespinho.com' + ogImage} siteUrl={'https://www.vetespinho.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <strong>Vetespinho Clinica Veterinária Lda.</strong><br />
Rua 26, 259<br /> 
4500-163 Espinho<br />
Portugal<br />
Loc: 41.011303, -8.636899<br />
Telefone: +351227328240<br />
Email: vetespinho@vetespinho.com<br />
Urgências: 933 669 303
    </MdxLayout>
    <div style={{
      maxWidth: 900,
      margin: '0 auto'
    }}>
    <ContactsPage mdxType="ContactsPage" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      